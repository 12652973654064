
import kaplayContext from "./kaplayContext"
import { SpriteComp } from "kaplay"

export function getAllSprites() : {[key: string]: SpriteComp} {
    const crustyCrewPath = "sprites/Treasure Hunters/Treasure Hunters/The Crusty Crew/Aseprite";
    const pinkStarPath = crustyCrewPath + "/Pink Star Spritesheet.png";

    const grass = kaplayContext.sprite(kaplayContext.loadSprite("grass", "sprites/grass.png"));
    const pinkStar = kaplayContext.sprite(kaplayContext.loadSprite("pinkStar", pinkStarPath, {
        sliceX: 8,
        sliceY: 10,
        anims: {
            idle: { from: 0, to: 7, loop: true, speed: 10 },
            walk: { from: 8, to: 13, loop: true, speed: 10 },
            jump: { from: 16, to: 18, loop: false, speed: 10 },
            fall: { from: 24, to: 24, loop: false, speed: 10 },
            ground: { from: 32, to: 33, loop: false, speed: 10 },
            //other animations... maybe death, hit or attack
        }
    }));

    return {
        grass,
        pinkStar
    };
}