import { AreaComp, BodyComp, DoubleJumpComp, GameObj, PosComp, ScaleComp, SpriteComp, Vec2, ZComp } from "kaplay";
import k from "./kaplayContext";
import { getAllSprites } from "./sprites";

class PinkStar {
    private gameObject: GameObj<PosComp & SpriteComp & ScaleComp & AreaComp & BodyComp & ZComp & DoubleJumpComp>;
    private isMoving: boolean;
    private movementSpeed: number;
    private jumpHeight: number;

    public constructor(position: {x: number, y: number, z: number}, scale: number, movementSpeed: number, jumpHeight: number, jumpNumber: number) {
        const sprites = getAllSprites();
        this.gameObject = k.add([
            k.pos(position.x, position.y),
            sprites.pinkStar,
            k.scale(scale),
            k.area({scale: 0.9}),
            k.body(),
            k.z(position.z),
            k.doubleJump(jumpNumber),
        ]);

        this.movementSpeed = movementSpeed;
        this.jumpHeight = jumpHeight
        this.isMoving = false;
        this.gameObject.flipX = true;
        this.gameObject.play("fall");

        this.setupEvents();
    }

    public jump() {
        this.gameObject.play("jump");
        this.gameObject.doubleJump(this.jumpHeight);
    }

    public goIdle() {
        this.gameObject.play("idle");
        this.isMoving = false;
    }

    public move(direction: Vec2) {
        if (!this.isMoving) {
            this.gameObject.play("walk");
            this.isMoving = true;
        }

        this.gameObject.flipX = direction.x > 0;
        this.gameObject.moveBy(
            direction.x*this.movementSpeed*k.dt(), 
            direction.y*this.movementSpeed*k.dt()
        );
    }
 
    private setupEvents() {
        this.gameObject.onGround(async () => {
            this.gameObject.play("ground");
            
            await k.wait(0.2);
            if (this.isMoving) {
                this.gameObject.play("walk");
                return;
            }
            this.gameObject.play("idle");
        });

        this.gameObject.onFall(() => {
            this.gameObject.play("fall");
        });
    }
}

export default PinkStar;