import kaplay from "kaplay";
import { ButtonsDef } from "kaplay/dist/declaration/app";

const buttons : ButtonsDef = {
    jump: {
        keyboard: ["space"],
        gamepad: ["south"],
    },
    left: {
        keyboard: ["left", "a"],
    },
    right: {
        keyboard: ["right", "d"],
    },
};

const k = kaplay({
    global: false,
    touchToMouse: true,
    font: "monogram",
    canvas: document.getElementById("game") as HTMLCanvasElement,
    buttons
});

export { buttons };

export default k;