import k from "./kaplayContext";
import { displayDialogue } from "./utils";
import PinkStar from "./pinkStar";

k.loadFont("monogram", "fonts/monogram.ttf");

k.setGravity(1600);
k.setBackground(k.YELLOW);

const platformHeight = 96;

const platform = k.add([
	k.rect(k.width(), platformHeight),
	k.pos(0, k.height() - platformHeight),
	k.area(),
	k.body({isStatic: true}),
	k.outline(2),
	k.color(120, 30, 200),
]);

const pinkStar = new PinkStar({x: 100, y: 100, z: 1}, 3, 250, 750, 1);

k.onResize(() => {
	platform.pos.x = 0;
	platform.pos.y = k.height() - platformHeight;
	platform.width = k.width();
});

k.onButtonPress("jump", () => {
    pinkStar.jump();
});

k.onButtonDown("left", () => {
	pinkStar.move(k.LEFT);
});

k.onButtonDown("right", () => {
	pinkStar.move(k.RIGHT);
});

k.onButtonRelease(["left", "right"], () => {
    pinkStar.goIdle();
});

k.onClick(() => {
	displayDialogue("Hello, World! alalalallallala", () => {});
});

/** TODO:
 * Choose different things to do when the player hit an object on the scene:
 *   - Big Map: (Pirate Treasure) Show a dialogue with my CV link/informations
 *   - Chest Key: (Merchant Ship) Show a dialogue with a small description of you
 *   - Red Diamond: (Pirate Treasure) Show a dialogue with my passions
 *   - Blue Diamond: (Pirate Treasure) Show a dialogue with my skills
 *   - Project icons: Think about it!
 */